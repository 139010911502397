import React from 'react';
import SbEditable from 'storyblok-react';
import StartPageHero from './bloks/StartPageHero.loadable';
import FlexibleModule from './bloks/FlexibleModule.loadable';
import LinkButton from './bloks/LinkButton.loadable';
import LinkButtons from './bloks/LinkButtons.loadable';
import Journal from './bloks/JournalBlock.loadable';
import FeaturedCategories from './bloks/FeaturedCategories.loadable';
import FeaturedCategory from './bloks/FeaturedCategory.loadable';
import FeaturedProducts from '../../StartPage/Content/FeaturedProducts.loadable';
import CampaignOneColumn from './bloks/campaign/CampaignOne.loadable';
import CampaignTwoColumns from './bloks/campaign/CampaignTwo.loadable';
import YotpoGallery from '../../ProductPage/YotpoGallery.loadable';
import FindifyRecommendationsBlok from './bloks/FindifyRecommendations.loadable';
import Wysiwyg from './bloks/Wysiwyg.loadable';
import KlaviyoForm from './bloks/KlaviyoForm.loadable';
import ImageLayout from './bloks/ImageLayout.loadable';
import ArticleHero from './bloks/ArticleHero.loadable';
import TextBlock from './bloks/TextBlock.loadable';
import QuoteBlock from './bloks/QuoteBlock.loadable';
import VideoPlayer from './bloks/VideoPlayer.loadable';
import ProductHighlight from './bloks/ProductHighlight.loadable';

const FallbackComponent = ({ component }) => (
  <div>The component "{component}" has not been created yet.</div>
);

export const ComponentsBloks = {
  flexible_module: FlexibleModule,
  link_button: LinkButton,
  link_buttons: LinkButtons,
  start_page_hero: StartPageHero,
  featured_categories: FeaturedCategories,
  featured_category: FeaturedCategory,
  featured_products: FeaturedProducts,
  campaign_one_column: CampaignOneColumn,
  campaign_two_columns: CampaignTwoColumns,
  journal: Journal,
  yotpo: YotpoGallery,
  findify_recommendations: FindifyRecommendationsBlok,
  wysiwyg: Wysiwyg,
  klaviyo_form: KlaviyoForm,
  image_layout: ImageLayout,
  article_hero: ArticleHero,
  text_block: TextBlock,
  quote_block: QuoteBlock,
  video_player: VideoPlayer,
  product_highlight: ProductHighlight,
  // fallback
  __fallback: FallbackComponent
};

const DynamicComponent = ({ story }) => {
  if (Array.isArray(story)) {
    return story.map(blok => <DynamicComponent story={blok} key={blok._uid} />);
  }
  if (!story || !story?.component) return null;
  const ComponentBlok =
    ComponentsBloks[story.component] ?? ComponentsBloks['__fallback'];
  return (
    <SbEditable content={story}>
      <ComponentBlok {...story} />
    </SbEditable>
  );
};

export default DynamicComponent;
